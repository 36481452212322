import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const AdCMP = ({ AdSlot, isTagNeeded = false, adSize = '' }) => {
    const adElementId = uuidv4();
    const [isAdModalLoading, setIsAdModalLoading] = useState(true);
    const adRef = useRef(null);

    const [width, height] = adSize.split('x').map(Number);
    const adStyle = {
        width: `${width || 300}px`, // Default width
        height: `${height || 250}px`, // Default height
    };

    useEffect(() => {
        const checkAdStatus = () => {
            const adElement = adRef.current;
            const adStatus = adElement?.getAttribute('data-adsbygoogle-status');
            const adFillStatus = adElement?.getAttribute('data-ad-status');
            setIsAdModalLoading(adStatus === null || adFillStatus !== 'filled');
        };

        const observer = new MutationObserver(checkAdStatus);
        if (adRef.current) {
            observer.observe(adRef.current, { attributes: true });
        }

        return () => {
            observer.disconnect();
        };
    }, [AdSlot]);

    useEffect(() => {
        if (adRef.current && adRef.current.offsetWidth > 0) {
            try {
                window.adsbygoogle = window.adsbygoogle || [];
                window.adsbygoogle.push({});
            } catch (e) {
                console.error("Adsbygoogle failed to load:", e);
            }
        }
    }, [AdSlot, isAdModalLoading]);

    return (
        <div
            style={{
                width: `${width || 300}px`,
                minHeight: `${height || 250}px`,
            }}
            className={`py-3 mx-auto flex flex-col items-center relative ${isAdModalLoading ? 'overflow-hidden' : ''
                }`}
        >
            {isTagNeeded && !isAdModalLoading && (
                <p className="text-[8px] uppercase">Advertisement</p>
            )}
            <div className="m-auto" style={{ position: 'relative', ...adStyle }}>
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    id={adElementId}
                    data-ad-client="ca-pub-8383910619750608"
                    data-ad-slot={AdSlot}
                    data-full-width-responsive="true"
                    data-ad-format="auto"
                    ref={adRef}
                ></ins>
            </div>
        </div>
    );
};

export default AdCMP;
